
<svelte:window on:cart_update={cartUpdate}/>

<script>
  export let product_id
  export let moq = 1
  export let show_remove = false

  moq = parseInt(moq)

  // Cast Product ID to an int (its a string otherwise)
  product_id = parseInt(product_id)

	// Set default quantiy zero
	let quantity = 0
	let last_quantity = 0

	// Load initial quantity in cart from window
	if (window.__CART__ && window.__CART__.items) {
		// Set both Last and current quantity initial value
		last_quantity = quantity = window.__CART__.items.filter(i => i.product_id == product_id).reduce((qty, i) => qty + i.quantity, 0)
  }
  

	// Misc parameters
	let updating_cart = false
	let notice = ''
  let notice_timeout = null
  let input_element = null

  // This is fired from Window:cart_update event. used when there are multiple add-to-carts for a single product_id e.g Cart-Display
  const cartUpdate = () => {
    const reported_quantity = window.__CART__.items.filter(i => i.product_id == product_id).reduce((qty, i) => qty + i.quantity, 0)
    if (reported_quantity !== last_quantity) {
      last_quantity = reported_quantity
      quantity = reported_quantity
    }
  }

  // Watch for changes to quantity from input field and run update (TODO: debounce?)
	$: updateCart(quantity)
	
	const setNotice = (message) => {
		notice = message
		clearTimeout(notice_timeout)
		notice_timeout = setTimeout(() => {
			notice = ''
		}, 3000)
	}

	const updateCart = async (new_quantity) => {
    // If focus is currently on the input, exit
    if (input_element && (document.activeElement === input_element || (input_element.shadowRoot && input_element.shadowRoot.activeElement === input_element))) return
    // input_element && console.log(input_element, input_element.shadowRoot.activeElement, document.activeElement)
		// If quantity has not changed, exit
		if (new_quantity === last_quantity) return


		try {
			// Displays the Updating message
      updating_cart = true
      // Reset notice so the message disappears
			notice = ''
			// Send request to server to update cart
			// const response = await fetch('https://jsonplaceholder.typicode.com/posts', {
			const response = await fetch('/cart/update', {
        method: 'POST',
        headers: {
          'X-CSRF-TOKEN': window && window.Laravel && window.Laravel.csrfToken,
          'Content-Type': 'application/json'
        },
				body: JSON.stringify({
					product_id,
					quantity: new_quantity
				})
			})
      const data = await response.json()

			// Now server is updated we can update last_quantity
			last_quantity = new_quantity
      // Update quantity
      // quantity = new_quantity

			// Set the notice to response with fallback if not specified
      setNotice(data.notice || 'Cart updated')
      
      if('cart' in data) {
        window.__CART__ = data.cart
        window.dispatchEvent(new Event('cart_update'))
      }

		} catch (error) {
			// Display an error message
			setNotice('Error updating cart')

		} finally {
			// Hide the Updating message
			updating_cart = false
		}
	}
</script>

<style>
  
</style>

<section class="add-to-cart" on:click|preventDefault|stopPropagation="" part="add-to-cart">
  <!-- {#if updating_cart}
    <span part="updating">Updating</span> -->
  {#if quantity}{#if show_remove}
  <button type="button" class="btn btn-tertiary d" on:click={() => quantity = 0}><i
    class="sr">Remove From Cart</i>×</button>
    {/if}
    <button part="button sub" type="button" class="btn btn-secondary l" on:click={() => quantity = quantity - moq}><i
        class="sr">Reduce Cart Quantity</i>−</button>
      <label><span class="sr">Quantity in Cart</span>
    <input part="input" on:keydown="{e => { e.key === 13 && updateCart(quantity)}}" on:blur="{e => updateCart(quantity)}" bind:this="{input_element}" type="number" bind:value={quantity} min="0" max="9999" />
  </label>
    <button part="button main" type="button" class="btn btn-secondary r" on:click={() => quantity = quantity + moq}><i
        class="sr">Increase Cart Quantity</i>+</button>
        
  {:else}
    <button part="button add" type="button" class="btn btn-primary add" on:click={() => quantity = moq}>Add to Cart</button>
  {/if}
  {#if notice}
  <span class="notice" part="notice">{@html notice}</span>
  {/if}
</section>