import component from "svelte-tag"
import AddToCart from './AddToCart.svelte';

// const addtocart = new AddToCart({
// 	target: document.body,
// 	props: {
// 		product_id: 12345
// 	}
// });

new component({
    component: AddToCart,
    tagname: "add-to-cart",
    // href="/your/stylesheet.css",
    attributes:["product_id"]
})

// export default AddToCart;
